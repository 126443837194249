@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
body {
  background-color: black;
}
/* Switch */
.ant-switch {
  background-color: #000 !important;
}
.ant-switch-handle::before {
  background-color: aqua !important;
}
.ant-switch-checked {
  background: #777879 !important;
}
.ant-switch-inner-unchecked {
  background-color: #000 !important;
}

.border-gradient {
  border-color: linear-gradient(#f5f7fa00, #00f9a9);
}
.bg_gradient {
  box-shadow: 0px 4px 4px 0px #090d141f;

  box-shadow: -2px -2px 4px 0px #e0e0ff05;

  box-shadow: 0px 1px 2px 0px #090d143d;
}
.tradingview_bce7f-wrapper {
}
.btn-gradient {
  border-style: solid;
  border-image: linear-gradient(to right, #4f9dff, #67ffd1) 1;
}
.btn-gradient1 {
  border-image: linear-gradient(to right, #4f9dff, #67ffd1) 1;
}

/* bar-chart */
.canvasjs-chart-canvas {
  background-color: rgba(0, 0, 0, 0.3);
}
.canvasjs-chart-toolbar {
  display: none !important;
}
.canvasjs-chart-credit {
  display: none;
}
::-webkit-scrollbar {
  display: none;
}

.buy-button {
  border: none;
  background: linear-gradient(180deg, rgba(245, 247, 250, 0) 0%, #00f9a9 100%);
  border-radius: 1rem;
  padding: 1px;
  box-shadow:
    0px 4px 4px 0px rgba(9, 13, 20, 0.12),
    -2px -2px 4px 0px rgba(224, 224, 255, 0.02),
    0px 1px 2px 0px rgba(9, 13, 20, 0.24),
    0px 8px 30px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(56px);
}
.buy-button-light {
  border: none;
  background: linear-gradient(180deg, #fff 0%, #00f9a9 100%);
  border-radius: 1rem;
  padding: 1px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(56px);
}
.buy-button-disabled {
  border: none;
  background: linear-gradient(180deg, rgba(245, 247, 250, 0) 0%, #4A4B4D 100%);
  border-radius: 1rem;
  padding: 1px;
  box-shadow:
    0px 4px 4px 0px rgba(9, 13, 20, 0.12),
    -2px -2px 4px 0px rgba(224, 224, 255, 0.02),
    0px 1px 2px 0px rgba(9, 13, 20, 0.24),
    0px 8px 30px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(56px);
}
.buy-button-light-disabled {
  border: none;
  background: linear-gradient(180deg, #fff 0%, #4A4B4D 100%);
  border-radius: 1rem;
  padding: 1px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(56px);
}
.buy-button-inner {
  background: linear-gradient(135deg, #222529 0%, #15181c 100%);
  padding: 1rem 1.5rem;
  border-radius: 1rem;
}
.buy-button-inner-light {
  background: linear-gradient(135deg, #fff 0%, #f1fff8 100%);
  padding: 1rem 1.5rem;
  border-radius: 1rem;
}
.button-text {
  font-size: 1rem;
  background: linear-gradient(334deg, #0ff 0%, #00f9a9 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.button-text-light {
  font-size: 1rem;
  background: linear-gradient(334deg, #107569 0%, #107569 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* tab-select */

.social_web_login {
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  background: #0f1012;
  padding: 8px, 24px, 8px, 24px;
  border-top-left-radius: 13px 15px;
}
.social_web_login_light {
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  background: #eff7f8;
  padding: 8px, 24px, 8px, 24px;
  border-top-left-radius: 13px 15px;
}
.react-tabs__tab--selected.social_web_login {
  background: #1f2122;
  border: none;
  color: white;
  font-weight: 700;
  outline: none;
  border: 0px, 0px, 1px, 0px;
  padding: 8px, 24px, 8px, 24px;
}
.react-tabs__tab--selected.social_web_login_light {
  background: white;
  border: none;
  color: #364152;
  font-weight: 700;
  outline: none;
  border: 0px, 0px, 1px, 0px;
  padding: 8px, 24px, 8px, 24px;
}

.social_web_login_1 {
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  background: #0f1012;
  padding: 8px, 24px, 8px, 24px;
  border-top-right-radius: 13px 15px;
}
.social_web_login_1_light {
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  background: #eff7f8;
  padding: 8px, 24px, 8px, 24px;
  border-top-right-radius: 13px 15px;
}
.react-tabs__tab--selected.social_web_login_1 {
  background: #1f2122;
  border: none;
  color: #ffffff;
  font-weight: 700;
  outline: none;
  border: 0px, 0px, 1px, 0px;
  padding: 8px, 24px, 8px, 24px;
}
.react-tabs__tab--selected.social_web_login_1_light {
  background: white;
  border: none;
  color: #364152;
  font-weight: 700;
  outline: none;
  border: 0px, 0px, 1px, 0px;
  padding: 8px, 24px, 8px, 24px;
}

.react-tabs__tab--selected.social_web_login_2 {
  background: #1f2122;
  color: #ffffff;
  font-weight: 700;
  outline: none;
  border: 0px, 0px, 2px, 0px;
  padding: 8px, 24px, 8px, 24px;
  border-top-left-radius: 13px 15px !important;
}
.react-tabs__tab--selected.social_web_login_2_light {
  background: white;
  color: #364152;
  font-weight: 700;
  outline: none;
  border: 0px, 0px, 2px, 0px;
  padding: 8px, 24px, 8px, 24px;
  border-top-left-radius: 13px 15px !important;
}
.react-tabs__tab--selected.social_web_login_3 {
  background: #1f2122;
  color: #ffffff;
  font-weight: 700;
  outline: none;
  padding: 8px, 24px, 8px, 24px;
}
.react-tabs__tab--selected.social_web_login_3_light {
  background: white;
  color: #364152;
  font-weight: 700;
  outline: none;
  padding: 8px, 24px, 8px, 24px;
}
.react-tabs__tab--selected.social_web_login_4 {
  background: #1f2122;
  color: #ffffff;
  font-weight: 700;
  outline: none;
  padding: 8px, 24px, 8px, 24px;
  border-top-right-radius: 13px 15px !important;
}
.react-tabs__tab--selected.social_web_login_4_light {
  background: white;
  color: #364152;
  font-weight: 700;
  outline: none;
  padding: 8px, 24px, 8px, 24px;
  border-top-right-radius: 13px 15px !important;
}

/* bar-chart */
@media screen and (max-width: 426px) {
  .recharts-wrapper {
    width: 21rem !important;
  }
}

.social_web_login_2 {
  align-self: stretch;
  background: #0f1012;
  border: 0px, 0px, 2px, 0px;
  padding: 8px, 24px, 8px, 24px;
  border-top-left-radius: 13px 15px !important;
}
.social_web_login_3 {
  background: #0f1012;
  padding: 8px, 24px, 8px, 24px;
}
.social_web_login_4 {
  background: #0f1012;
  padding: 8px, 24px, 8px, 24px;
  border-top-right-radius: 13px 15px !important;
}
.order_container {
  border-radius: 16px;
  border: 1px solid rgba(245, 247, 250, 0.06);
  background: linear-gradient(135deg, rgba(245, 247, 250, 0.06) 0%, rgba(245, 247, 250, 0) 100%);
  box-shadow:
    0px 4px 4px 0px rgba(9, 13, 20, 0.12),
    -2px -2px 4px 0px rgba(224, 224, 255, 0.02),
    0px 1px 2px 0px rgba(9, 13, 20, 0.24);
  backdrop-filter: blur(54.36563491821289px);
}
.react-tabs__tab--selected.Tab_container {
  border-color: white !important;
  font-weight: 700;
}

.connect_bg_container {
  background: var(--color-white-25, rgba(255, 255, 255, 0.06));
 
}
.selected-tab {
  border-bottom: 1px solid var(--color-white-50, rgba(255, 255, 255, 0.10)); /* Change the border properties as needed */
  border-radius: var(--spacing-0, 0rem); /* Change the border radius value as needed */
  /* Other styles for the selected tab */
}

 